import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogFragment from "../components/fragments/blog-fragment"

const BlogPage = (props) => (
  <Layout {...props}>
    <SEO title="About Me" />
    <BlogFragment />
  </Layout>
)

export default BlogPage
