import React from "react"
import { Box as GBox } from "grommet"
import { Title, BoxWrapper } from "./styles"
import FragmentWrapper from "../fragment-wrapper"

const BlogFragment = () => {
  return (
    <FragmentWrapper
      rightContent={() => <div />}
    >
      <GBox justify="center" align="center" fill pad={{ horizontal: 'large' }}>
        <GBox>
          <BoxWrapper>
            <GBox id="inf" justify="end">
              <Title float>Coming soon</Title>
            </GBox>
          </BoxWrapper>
        </GBox>
      </GBox>
    </FragmentWrapper>
  )
}
export default BlogFragment
